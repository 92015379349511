/* src/styles/meetVersion.css */


/* body {
  background-color: #141332;
} */
/* 主容器 */
/* .page-content {
  padding: 20px;

  color: #ffffff;
  background-color: #141332;
} */

/* Meet 版本行的樣式 */



.meet-versions-row {

  display: flex;
  align-items: center;
  justify-content: space-between;

}

/* 版本下拉選單容器 */
.meet-versions-dropdown {
  display: flex;
  align-items: center;
}

/* 下拉按鈕 */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #282c34;
  color: white;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 200px; /* 調整寬度 */
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-toggle:hover {
  border-color: #4a7cb1;
  box-shadow: 0 0 8px rgba(74, 124, 177, 0.3);
}

.dropdown-toggle::after {
  content: '\25BC'; /* Unicode character for a downward arrow */
  margin-left: 10px;
  color: white;
}

/* 下拉選單 */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #1D1D41;
  border: 1px solid #4a7cb1;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

.dropdown-item, .dropdown-item-1 {
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #ffffff;
}

.dropdown-item {
  justify-content: space-between;
  border-bottom: 1px solid #4a7cb1; /* 分隔選項的底線 */
}

.dropdown-item-1 {
  border-bottom: 1px solid #4a7cb1;
}

.dropdown-item:hover,
.dropdown-item-1:hover {
  background-color: #282c34;
}

/* 新增版本按鈕 */
.add-version-button {
  background-color: #575da4;
  border: none;
  color: white;
  padding: 5px;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 10%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin-left: 10px; /* Add margin to create space between the dropdown and the button */
}


.input-version-fields input {
  margin-left: 3px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 110px;
}

/* 新增變數按鈕 */
.add-button {
  background-color: #405a84;
  margin-left: 8px;
}

.add-button:hover {
  background-color: #1a406f;
}

/* 新增變數的容器 */
.add-variables-container {
  display: flex;
  align-items: center;
}

.table-container {
  overflow-x: auto;
  margin: 20px 0 ;
}

.config-table {
  width: 100%;
  border-collapse: separate;
  border: none; /* 移除表格边框线 */
  background-color: #1D1D41; /* 设置表格背景颜色 */
  border-radius: 8px; /* 设置表格圆角 */
  overflow: hidden; /* 隐藏圆角外的内容 */
}


.config-table th,
.config-table td {

  background-color: #1D1D41 !important; /* 明確設置欄位背景顏色並優先應用 */
  border: none; /* 移除单元格边框线 */
  padding: 20px 10px!important;
  text-align: left;
}

.config-table th {

  background-color: #1D1D41;
  font-weight: bold;
  color: #ffffff;
}
.config-table td {

  background-color: #1D1D41;
  color: #ffffff;
}

.config-table td:last-child {
  text-align: center;
}

.config-table td {
  vertical-align: middle; 
}

.config-table td:last-child {
  text-align: left; /* 将最后一列(ID列)置左对齐 */
}

.select-container .delete-version-button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c9302c;
}
.delete-button-inline {
  background-color: transparent; /* 預設背景色 */
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 20%;
}

.delete-button-inline:active {
  background-color: red; /* 點擊時的紅色背景 */
  color: white; /* 可選，讓圖標顯得更清晰 */
}

.config-table .delete-button-inline {
  color: white;
  background-color: #d9534f;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.delete-button-all {
  color: white;
  background-color: #c9302c;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
}



.delete-button-inline:hover {
  background-color: #c9302c ;
}
.delete-button-text:hover {
  background-color: #c9302c;
}
.select-container .delete-version-button:hover {
  background-color: #c9302c;
}

.platform-android {
  color: green !important;
}
.platform-ios {
  color: yellow !important;
}