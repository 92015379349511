body {
    margin:0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer-container {
    background-color: #282c34; 
    color: white; 
    padding: 20px; 
    font-size: 20px;
   
    
  }