/* dropdown.css */
.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #282c34;
    color: white;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    width: 300px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-toggle:hover {
    border-color: #4a7cb1;
    box-shadow: 0 0 8px rgba(74, 124, 177, 0.3);
  }
  
  .dropdown-toggle::after {
    content: '\25BC'; /* Unicode character for a downward arrow */
    margin-left: 10px;
    color: white;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #1D1D41;
    border: 1px solid #4a7cb1;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
  }
  
  .dropdown-item,
  .dropdown-item-1 {
    padding: 8px;
    display: flex;
    align-items: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: white;
    border-bottom: 1px solid #4a7cb1;
  }
  
  .dropdown-item:last-child,
  .dropdown-item-1:last-child {
    border-bottom: none;
  }
  
  .dropdown-item:hover,
  .dropdown-item-1:hover {
    background-color: #282c34;
  }
  
  .dropdown-item label,
  .dropdown-item-1 label {
    flex-grow: 1;
    margin-left: 8px;
  }
  
  .dropdown-item-1 input {
    margin-right: 8px;
  }
  