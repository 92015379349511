
 

  .add-button-1 {
    display: inline-block;
    margin: 10px 0;
    margin-right: 10px;
    padding: 6px 12px;
    font-size: 20px;
    color: #fff;
    background-color: #3b5e84;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .add-button-.add-button-1 {
    background-color: #0056b3;
  }

  .add-button-container {
    display: flex;
    align-items: center;
    margin-top: 20px; /* 調整這個值來控制整個容器的垂直位置 */
  }

  .input-fields {
    display: flex  ;
    align-items: center;
    gap: 10px; /* 調整這個值來控制各個輸入框和按鈕之間的間距 */
  }

  .input-fields input {
    padding: 8px !important;
    font-size: 14px;
    border: 10px solid #ddd;
    border-radius: 4px;
    width: 200px ;  /* 调整输入框宽度 */
  }

  .confirm-button {
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    background-color: #5c9095;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .confirm-button:hover {
    background-color: #218838;
  }
  
  .custom-select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f2f2f2;
    cursor: pointer;
  }
  
  .custom-select:focus {
    border-color: #4a7cb1;
  }
  
  
  .add-config-container select {
    cursor: pointer;
  }
  
  .add-config-container button {
    padding: 8px 16px;
    font-size: 14px;
    color: #ffffff;
    background-color: #5cb85c;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-config-container button:hover {
    background-color: #4cae4c;
  }
  
  /* 驗證消息樣式 */
  .validation-message {
    color: red;
    margin-top: 10px;
  }
  
  /* 表格樣式 */
  .appconfig-table-container{
    overflow-x: auto;
    margin-top: 20px 0;
  }
  
  .appconfig-config-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1D1D41;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .appconfig-config-table th,
  .appconfig-config-table td {
    padding: 12px 16px;
    text-align: left;
  }
  
  .appconfig-config-table th {
    background-color: #1D1D41;
    color: #ffffff;
  }
  
  .config-table td {
    background-color: #282c34;
    color: #ffffff;
  }
  
  /* 平台列表樣式 */
  .config-table td ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .config-table td ul li {
    color: #b3b3b3;
  }
  
  /* 編輯和刪除按鈕樣式 */
  button {
    padding: 6px 12px;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 5px;
  }

  .edit-button,
.update-button,
.delete-button {
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 4px 2px;
  transition: background-color 0.3s ease;
}

.edit-button {
  background-color: #ffc107;
  padding: 10px 20px;
}

.edit-button:hover {
  background-color: #ffb300;
}
.delete-button {
  background-color: #d9534f;
}

.delete-button:hover {
  background-color: #c9302c;
}

.delete-button:before {
  content: '\1F5D1'; 
  margin-right: 6px;
  font-size: 16px;
}
  
 
  /* 編輯模式下的輸入框 */
  input[type="text"] {
    padding: 4px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100px;
  }
  
  input[type="text"]:focus {
    border-color: #4a7cb1;
  }
  