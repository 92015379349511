/* admin.css */
body {
    background-color: #282c34;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    overflow: hidden; 
  }
  
  .admin-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    border: none; /* 确保没有边框 */
  }
  
  .sidebar {
    width: 20%;
    background-color: #1D1D41;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100vh;
  }
  
  .sidebar h2 {
    display: flex;
    align-items: center;
  }
  
  .sidebar img {
    width: 40px;
    height: 30px;
    margin-right: 10px;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .sidebar ul li img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .sidebar ul li.active {
    background-color: #607def;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
  }
  
  .content {
    width: 85%;
    padding: 20px;
    background-color: #141332;
    color: #ffffff;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100vh;
    border: none; /* 移除蓝色边框 */

  }
  